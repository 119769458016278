import Vue from 'vue'
import Router from 'vue-router'
import 'vant/lib/index.css';
import storage from 'good-storage'



import { Toast } from 'vant';
Vue.use(Toast)
import { Uploader } from 'vant';
Vue.use(Uploader);
import { Empty } from 'vant';
Vue.use(Empty);
import { List } from 'vant';
Vue.use(List);
import { Popup } from 'vant';
Vue.use(Popup);
import { Dialog } from 'vant';
Vue.use(Dialog);
import { Icon } from 'vant';
Vue.use(Icon);

const Authority = ()=> import('@/components/Authority')
const CircleOfFriends = ()=> import('@/components/CircleOfFriends')
const EditCircle = ()=> import('@/components/EditCircle')
const MessageList = ()=> import('@/components/MessageList')
const CircleDetail = ()=> import('@/components/CircleDetail')




Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'CircleOfFriends',
      component: CircleOfFriends,
      meta: {
        title: '朋友圈'
      }
    },
    {
      path: '/Authority',
      name: 'Authority',
      component: Authority,
      meta: {
        title: '用户授权'
      }
    },
    {
      path:'/CircleOfFriends',
      name: 'CircleOfFriends',
      component: CircleOfFriends,
      meta: {
        title: '朋友圈'
      }
    },
    {
      path:'/EditCircle',
      name: 'EditCircle',
      component: EditCircle,
      meta: {
        title: '编辑'
      }
    },
    {
      path:'/MessageList',
      name: 'MessageList',
      component: MessageList,
      meta: {
        title: '消息列表'
      }
    },
    {
      path:'/CircleDetail',
      name: 'CircleDetail',
      component: CircleDetail,
      meta: {
        title: '详情'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  //  第一次进入项目
  let baseInfo = storage.get("baseInfo")
  if(!baseInfo || (!baseInfo.external_userid && !baseInfo.userId)){
    if ( to.path != "/Authority") {
      console.log('???')
      window.localStorage.setItem("beforeLoginUrl", to.fullPath)// 保存用户进入的url
      window.localStorage.setItem("z_id", to.query.z_id)// 保存用户进入的url
      // window.localStorage.setItem("beforeLoginUrlNoParams", to.path)// 保存用户进入的url
      // console.log('??????',to.query)
      // if(to.query){
      //   window.localStorage.setItem("queryItem", JSON.stringify(to.query))
      // }
      next("/Authority")
      return false;
    }
  } 
  next()
})

router.afterEach(() => {
  window.scrollTo(0,0)
})

export default router
