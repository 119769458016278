<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
	data(){
		return{
			baseInfo:''
		}
	},
	mounted(){
		// console.log(this.$route)
		// this.baseInfo = this.Storage.get('baseInfo')
		// if(this.baseInfo){
		// 	this.axios({
		// 		method: 'POST',
		// 		url: '/scrm/auth/wework',
		// 		data:{
		// 			z_id:this.$route.query.z_id,
		// 			userId:this.baseInfo.userId,
		// 			external_userid:this.baseInfo.external_userid
		// 		}
		// 	}).then((res) => {
		// 		if(res.data.code == 0){
		// 			this.Storage.set('userBaseInfo', res.data.data)
		// 			this.userBaseInfo = res.data.data;
		// 		}
		// 	})
		// }
	}
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #141414;
  font-size: 0.16rem;
}
#app .van-cell::after{
  content: none;
}
body,img,p,div{
  margin: 0;
  padding: 0;
}
.refresh.van-icon{ 
	font-size: 0.3rem;
	position: absolute;
	left: 0.08rem;
	top: 0.15rem;
	margin-top: 0;
	color: white;
}
.pre{
	overflow: auto;
	box-sizing: border-box;
	width: 100%;
	padding: 7px 16px;
	overflow: hidden;
	color: #323233;
	font-size: 14px;
	line-height: 18px;
	background-color: #fff;

	min-height: 24px;
	max-height: 88px;
	height: 100%;
  z-index: 10;
  text-align: left;
  width: 100%;
  user-modify: read-write;
  user-modify: read-write-plaintext-only;
  -webkit-user-modify: read-write-plaintext-only;
  outline: none;
  white-space: pre-wrap;      
  white-space: -moz-pre-wrap; 
  white-space: -pre-wrap;     
  white-space: -o-pre-wrap;   
  word-wrap: break-word;      
}
.pre>img{
	display: inline-block;
	width: 16px;
	margin-right: 4px;
	vertical-align: middle;
  margin-top: -3px;
}
#MessageList .van-list__finished-text{
	color: #141414;
	font-size: 0.14rem;
}


#EditCircle .pre{
	padding: 10px 0;
}

#EditCircle .van-icon-photograph::before {
  content: '\F0A2';
}
#EditCircle .van-uploader__preview,#EditCircle .van-uploader__upload{
  width: 33%;
  position: relative;
  padding-bottom: 33%;
  height: 0;
  overflow: hidden;
  margin: 0;
}
#EditCircle .van-uploader__upload-icon {
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 24px;
}
#EditCircle .van-uploader__preview-image{
  position: absolute;
  width: 100%;
  height: 100%;
}
#EditCircle .van-uploader{
  width: 100%;
}
#EditCircle .van-uploader__wrapper >div:nth-child(3n+2){
  /* justify-content: space-between; */
	margin: 0 0.5%;
}

#CircleOfFriends .van-field__body textarea{
  line-height: normal;
}
#CircleOfFriends .van-uploader{
  display: none !important;
}
</style>
<style>
#emotions{
	left:0;
	width: 100%;
	font-size:12px;
	background:#fff;
	border: 1px solid #E8E8E8;
}
#emotions a{
	color: #9ABBC8;
	padding:2px 7px;
	text-decoration:none;
}
#emotions img{
	border:0;
}
#emotions div{
	margin:5px 10px;
	padding:1px;
	overflow: hidden;
}
#emotions #prev,
#emotions #next
{
	margin-left:3px;
	background:#eee;
}
#emotions .categorys{
	color:#ccc;
	height:23px;
	color: #9ABBC8;
	overflow:hidden;
}
#emotions .categorys a{
	display: inline-block;
}
#emotions .categorys a:hover{
	text-decoration:underline;
}
#emotions .categorys a.current{
	cursor: default;
	background: #F0F0F0;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}
#emotions .container{
	padding:1px;
	overflow: auto;
	text-align: center;
	margin: 0;
	max-height: 2.5rem;
}
#emotions .container a{
	margin: 5px;
	/* float:left; */
	display: inline-block;
	width:26px;
	height:22px;
	text-align:center;
	padding: 4px 2px;
	/* margin: -1px 0 0 -1px; */
	/* border: 1px solid #e8e8e8; */
}
#emotions .container a:hover{
	z-index:2;
	position:relative;
	/* border:1px solid #0095cd; */
}
#emotions .page{
	text-align:right;
}
#emotions .page a.current{
	color: #666;
	background-color:#fff;
}
#emotions .page a{
	margin-left:3px;
	color: #0078B6;
	background-color:#f3f3f3;
}
#emotions .page a:hover{
	background-color:#e7e7e7;
}
</style>

