import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import Global from './assets/js/global'
import store from './assets/js/store.js'
import storage from 'good-storage'



// require('@/assets/js/vconsole')

Vue.prototype.axios = axios
Vue.prototype.Global = Global
Vue.config.productionTip = false
Vue.prototype.Storage = storage



function getServerConfig(){
  return new Promise ((resolve, reject) => {
    axios.get('./serverConfig.json').then((result) => {
      axios.defaults.baseURL = result.data.nowurl;
      Global.nowurl = result.data.nowurl;
      Global.appid = result.data.appid;
      Global.expires_in = result.data.expires_in;
      resolve();
    }).catch((error) => {
      console.log(error);
      reject()
    })
  })
}

async function initNow(){
  await getServerConfig()
  axios.defaults.baseURL = 'https://api.jinlibx.com/api';
  axios.interceptors.request.use( (config) => {
    config.headers.accept = 'application/json';
    // 在发送请求之前做些什么
    vueObj.$toast({
      type: 'loading',
      forbidClick: true,
      overlay: true
    })
    return config;
  })

  axios.interceptors.response.use( (response) => {
    // console.log(response.data.code)
    let resCode = response.data.code
    vueObj.$toast.clear()
    switch (resCode){
      case 40001:
        vueObj.$router.push('/Authority')
        break
      default:
        return response
    }
  }, function (error) {
    return Promise.reject(error);
  })

  const vueObj = new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}
initNow()