


function getQueryString( name ){
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
      return decodeURIComponent(r[2])
  }
  return null
}


function setCookie(cname, cvalue, exmins) {
  let d = new Date();
  d.setTime(d.getTime() + (exmins*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname){
  let strcookie = document.cookie;
  let arrcookie = strcookie.split("; ");
  for ( let i = 0; i < arrcookie.length; i++) {
    let arr = arrcookie[i].split("=");
    if (arr[0] == cname){
      return arr[1];
    }
  }
  return "";
}

function delCookie(cname) {     
  setCookie(cname, "", -1); 
}

function formatTimes(timelength){
  var timestampLogin = new Date(timelength.replace(/-/g, '/')).getTime()-1000 ;
  var seconds = (Date.parse(new Date())- timestampLogin)/1000 ;
  var hour = Math.floor(seconds/3600);
  var day =  Math.floor(hour/24)
  if(day>0){
    return day+'天前';
  }
  if(hour>0){
    return hour+'小时前';
  }
  var minute = Math.floor(seconds/60);
  console.log(minute)
  if(minute>0){
      return minute+'分钟前';
  }
  return seconds+'秒前';
}

var nowurl = 'https://moment.jinlibx.com'
var appid = 'ww8c97a2c97e732871'
export default {
  nowurl,
  appid,
  getQueryString,
  setCookie,
  getCookie,
  delCookie,
  formatTimes
}